import { useMemo, useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'
import { useSubscription } from 'react-stomp-hooks'
import { useParams } from 'react-router-dom'
import useAivatarDidMount from '../../../../hooks/useAivatarDidMount'
import useAudioControllerCallbacks from '../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { projectValueState } from '../../../../recoil/project/selectors'
import {
  focusedBoxIndexAmongSelectedBoxesState,
  editorAudioValueState,
  validBoxesByCategoryWithFilterState,
  viewerBoxesByCategoryState,
  focusedBoxState,
} from '../../../../recoil/texteditor/editor/selectors'
import { AUDIO_CRATE_STATUS, SENTENCEBOX_CATEGORY } from '../../../../recoil/texteditor/sentenceBox/atoms'
import { fetchCreateAudiosAsync } from '../../../../services/audio/fetchCreateAudios'
import { fetchListenAudioForBatchCreation } from '../../../../services/audio/useListenAudio'
import { CALL_STATE } from '../../../../services/constants'
import { isPlayingState } from '../../../../recoil/audiocontroller/selectors'

export const FIRST_CREATION_ACTION = {
  NEW: 'NEW',
  CREATION: 'CREATION',
  PLAY: 'PLAY',
}

export const audioCreationCount = 8

const useFirstAudioCreationHandler = () => {
  const { projectId } = useParams()
  const [actionType, setActionType] = useRecoilState(editorAudioValueState({ key: 'actionType' }))

  const setProjectApiState = useSetRecoilState(projectValueState({ key: 'apiState' }))
  const setProjectLoadingMessage = useSetRecoilState(projectValueState({ key: 'loadingMessage' }))
  const setProjectIsAudioLoading = useSetRecoilState(projectValueState({ key: 'isAudioLoading' }))

  const focusedBoxAudioState = useRecoilValue(focusedBoxState({ category: SENTENCEBOX_CATEGORY.AUDIO }))

  const optionViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.OPTION,
    }),
  )

  const generalViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )

  const audioViewerBoxes = useRecoilValue(viewerBoxesByCategoryState({ category: SENTENCEBOX_CATEGORY.AUDIO }))

  const {
    getWillCreateAudioBoxes,
    startPlayFocusedBox,

    setAudioById,
  } = useAudioControllerCallbacks()

  useSubscription(`/queue/project/${projectId}`, (message) => {
    const audio = JSON.parse(message.body)
    console.debug(`received ${audio.audioId}: ${audio.status}`)
    //! audio url 두번세팅되는문제 임시해결: 아직 완벽히 해결된거 아님. 2번올거 한번만 오도록 해야함
    const prevAudio = _.find(audioViewerBoxes, ['audioId', audio.audioId])
    if (!prevAudio.url && audio.status === AUDIO_CRATE_STATUS.COMPLETED) {
      fetchListenAudioForBatchCreation({
        audioId: audio.audioId,
      }).then((url) => {
        setAudioById({
          id: prevAudio.id,
          newValues: {
            status: AUDIO_CRATE_STATUS.COMPLETED,
            estimatedDurationMS: audio.duration,
            url,
            apiState: CALL_STATE.SUCCESS,
          },
        })
      })
    } else if (audio.status.toUpperCase() === 'FAIL') {
      console.log('received but audio fail', audio.audioId)
      setAudioById({
        id: prevAudio.id,
        newValues: {
          status: AUDIO_CRATE_STATUS.FAIL,
          apiState: CALL_STATE.ERROR,
        },
      })
    }
  })

  const [isCreatingFirstAudios, setCreatingFirstAudios] = useState(false)
  const isPlaying = useRecoilValue(isPlayingState)

  /** 뒤 오디오 생성 */
  const currentFocusedBoxIndex = useRecoilValue(focusedBoxIndexAmongSelectedBoxesState)
  const isNextAudioCreationIndex = useMemo(() => {
    if (currentFocusedBoxIndex === null) return false
    return currentFocusedBoxIndex % audioCreationCount === 1
  }, [currentFocusedBoxIndex])

  /** Methods */
  const setProjectLoading = (type) => {
    setProjectApiState(type)
    switch (type) {
      case CALL_STATE.FETCHING:
        setProjectLoadingMessage('오디오 생성 중...')
        setProjectIsAudioLoading(true)
        break
      case CALL_STATE.SUCCESS:
      case CALL_STATE.ERROR:
        setProjectLoadingMessage('')
        setProjectIsAudioLoading(false)
        break

      default:
        break
    }
  }
  const createFirstAudios = async () => {
    /** 후보 선정 */
    const willCreateAudioBoxes = getWillCreateAudioBoxes({
      start: 0,
      count: audioCreationCount,
    })

    if (willCreateAudioBoxes.length <= 0) {
      // 후보 박스 없으면 바로 재생 시작
      setActionType(FIRST_CREATION_ACTION.PLAY)
      return
    }

    setCreatingFirstAudios(true)
    setProjectLoading(CALL_STATE.FETCHING)
    for (const { id } of willCreateAudioBoxes) {
      setAudioById({
        id,
        newValues: {
          apiState: CALL_STATE.FETCHING,
        },
      })
    }

    const body = willCreateAudioBoxes.map((audio) => {
      const option = _.find(optionViewerBoxes, ['id', audio.id])
      const sentence = _.find(generalViewerBoxes, ['id', audio.id])
      return {
        text: sentence.text,
        voiceId: option.voiceId,
        sentenceId: sentence.sentenceId,
        audioId: audio.audioId,
        space: option.space,
        pitch: option.pitch,
        speed: option.speed,
        volume: option.volume,
        language: option.language,
      }
    })

    await fetchCreateAudiosAsync({
      body,
    }).then(({ data }) => {
      console.log(data)
    })

    setProjectLoading(CALL_STATE.SUCCESS)

    // make body

    // try {
    //   /** 오디오 생성 시작 */
    //   await fetchCreateAudios({
    //     audioIds: willCreateAudioBoxes.map((box) => box.audioId),
    //     accessToken
    //   })
    //     .then((createdAudioRes) => {
    //       const createListenPromises = willCreateAudioBoxes.map(
    //         (box, index) => {
    //           const { audioId, id } = box;
    //
    //           return fetchAudioInformationForBatchCreation({
    //             accessToken,
    //             audioId
    //           })
    //             .then((audioData) => {
    //               const { status, duration } = audioData;
    //               if (status === AUDIO_CRATE_STATUS.COMPLETED) {
    //                 setAudioById({
    //                   id,
    //                   newValues: {
    //                     status: AUDIO_CRATE_STATUS.COMPLETED
    //                   }
    //                 });
    //
    //                 return fetchListenAudioForBatchCreation({
    //                   accessToken,
    //                   audioId
    //                 }).then((url) => {
    //                   setAudioById({
    //                     id,
    //                     newValues: {
    //                       estimatedDurationMS: duration,
    //                       url,
    //                       apiState: CALL_STATE.SUCCESS
    //                     }
    //                   });
    //                 });
    //               } else {
    //                 // status 가 FAIL , READY 모두
    //                 setAudioById({
    //                   id,
    //                   newValues: {
    //                     status: AUDIO_CRATE_STATUS.FAIL,
    //                     apiState: CALL_STATE.SUCCESS
    //                   }
    //                 });
    //               }
    //             })
    //             .catch((error) => {
    //               setAudioById({
    //                 id: box.id,
    //                 newValues: {
    //                   apiState: CALL_STATE.ERROR,
    //                   status: AUDIO_CRATE_STATUS.FAIL
    //                 }
    //               });
    //             });
    //         }
    //       );
    //
    //       return Promise.all(createListenPromises);
    //     })
    //     .catch((error) => {
    //       throw new Error("createFirstAudios error", error);
    //     });
    //   setProjectLoading(CALL_STATE.SUCCESS);
    //   setActionType(FIRST_CREATION_ACTION.PLAY);
    // } catch (error) {
    //   for (const { id } of willCreateAudioBoxes) {
    //     setAudioById({
    //       id,
    //       newValues: {
    //         apiState: CALL_STATE.ERROR
    //       }
    //     });
    //   }
    //   setProjectLoading(CALL_STATE.ERROR);
    // }
  }

  const createNextAudios = async () => {
    console.log('create next audios')
    /** 후보 선정 */
    const willCreateAudioBoxes = getWillCreateAudioBoxes({
      start: currentFocusedBoxIndex + audioCreationCount - 1,
      count: audioCreationCount,
    })

    if (willCreateAudioBoxes.length <= 0) {
      // 후보 박스 없으면 암것도 안해~
      return
    }

    for (const { id } of willCreateAudioBoxes) {
      setAudioById({
        id,
        newValues: {
          apiState: CALL_STATE.FETCHING,
        },
      })
    }

    const body = willCreateAudioBoxes.map((audio) => {
      const option = _.find(optionViewerBoxes, ['id', audio.id])
      const sentence = _.find(generalViewerBoxes, ['id', audio.id])
      return {
        text: sentence.text,
        voiceId: option.voiceId,
        sentenceId: sentence.sentenceId,
        audioId: audio.audioId,
        space: option.space,
        pitch: option.pitch,
        speed: option.speed,
        volume: option.volume,
        language: option.language,
      }
    })

    await fetchCreateAudiosAsync({
      body,
    }).then(({ data }) => {
      console.log(data)
    })

    // try {
    //   /** 오디오 생성 시작 */
    //   await fetchCreateAudios({
    //     audioIds: willCreateAudioBoxes.map((box) => box.audioId),
    //     accessToken
    //   }).then((createdAudioRes) => {
    //     const createListenPromises = willCreateAudioBoxes.map((box, index) => {
    //       const { audioId, id } = box;
    //       return fetchAudioInformationForBatchCreation({
    //         accessToken,
    //         audioId
    //       })
    //         .then((audioData) => {
    //           const { status, duration } = audioData;
    //           if (status === AUDIO_CRATE_STATUS.COMPLETED) {
    //             setAudioById({
    //               id,
    //               newValues: {
    //                 status: AUDIO_CRATE_STATUS.COMPLETED
    //               }
    //             });
    //
    //             return fetchListenAudioForBatchCreation({
    //               accessToken,
    //               audioId
    //             }).then((url) => {
    //               setAudioById({
    //                 id,
    //                 newValues: {
    //                   estimatedDurationMS: duration,
    //                   url,
    //                   apiState: CALL_STATE.SUCCESS
    //                 }
    //               });
    //             });
    //           } else {
    //             // status 가 FAIL , READY 모두
    //             setAudioById({
    //               id,
    //               newValues: {
    //                 status: AUDIO_CRATE_STATUS.FAIL,
    //                 apiState: CALL_STATE.SUCCESS
    //               }
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           setAudioById({
    //             id: box.id,
    //             newValues: {
    //               apiState: CALL_STATE.ERROR,
    //               status: AUDIO_CRATE_STATUS.FAIL
    //             }
    //           });
    //         });
    //     });
    //
    //     return Promise.all(createListenPromises);
    //   });
    // } catch (error) {
    //   for (const { id } of willCreateAudioBoxes) {
    //     setAudioById({
    //       id,
    //       newValues: {
    //         apiState: CALL_STATE.ERROR
    //       }
    //     });
    //   }
    // }
  }

  useAivatarDidMount(async () => {
    if (!isNextAudioCreationIndex) return
    await createNextAudios()
  }, [isNextAudioCreationIndex])

  //! 초 레거시 코드. 해결법: 백엔드에서 patch시 READY로 변경하도록 해야됨
  useEffect(() => {
    if (focusedBoxAudioState) {
      const firstBoxAudioDone = !!focusedBoxAudioState.url
      if (isCreatingFirstAudios && firstBoxAudioDone) {
        if (!isPlaying) {
          setActionType(FIRST_CREATION_ACTION.PLAY)
          setCreatingFirstAudios(false)
        }
      }
    }
  }, [focusedBoxAudioState, isPlaying, isCreatingFirstAudios, setActionType])

  /** 오디오 생성 */
  useAivatarDidMount(async () => {
    if (actionType !== FIRST_CREATION_ACTION.CREATION) return
    setActionType(FIRST_CREATION_ACTION.NEW)
    await createFirstAudios()
  }, [actionType])

  /** 재생 */
  useAivatarDidMount(async () => {
    if (actionType !== FIRST_CREATION_ACTION.PLAY) return
    setActionType(FIRST_CREATION_ACTION.NEW)
    await startPlayFocusedBox()
  }, [actionType])

  return {
    actionType,
    setActionType,
    FIRST_CREATION_ACTION,
  }
}

export default useFirstAudioCreationHandler
