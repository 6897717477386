import { v4 as uuidv4 } from 'uuid'

import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { editorAudioState, editorState, sentenceBoxIdsState } from './atoms'
import {
  focusedBoxState,
  isSelectedBoxesEmptyState,
  isViewerBoxesEmptyState,
  selectedBoxIdsState,
  sentenceBoxesByTypeState,
  viewerBoxIdsState,
} from './selectors'
import {
  AUDIO_CRATE_STATUS,
  SENTENCEBOX_CATEGORY,
  SENTENCEBOX_TYPE,
  sentenceBoxAudioState,
  sentenceBoxOptionState,
  sentenceBoxState,
  SentenceBoxValuesForFetch,
} from '../sentenceBox/atoms'
import { addItemsAtIndex, removeItemAtIndex } from '../../../utils/AivatarArrayHandler'
import { selectMultipleBoxes } from './editorHelperCallbacks'
import { isValidSentenceBoxState, sentenceBoxOptionValueState, sentenceBoxValueState } from '../sentenceBox/selectors'
import { checkIsValidSentenceBoxLength, getSplitedCreatorTexts, updateFocused } from '../sentenceBox/sentenceBoxHelpers'
import {
  resetFirstIntervalAudio,
  resetIntervalAudio,
  resetSentenceBoxAudio,
} from '../../audiocontroller/audioControllerHelpers'
import { defaultVoiceState } from '../../workspace/selectors'
import { CALL_STATE } from '../../../services/constants'

const useEditorCallbacks = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language
  const string = locale.startsWith('ko') ? 'ko-KR' : 'en-US'

  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)

  /** Recoils */
  const defaultVoice = useRecoilValue(defaultVoiceState({ language: string }))

  useEffect(() => {
    if (!sentenceBoxValues.voiceId && defaultVoice) {
      setSentenceBoxValues({ ...sentenceBoxValues, voiceId: defaultVoice.id, language: string })
    }
  }, [defaultVoice, sentenceBoxValues, setSentenceBoxValues, string])

  const defaultSentenceBoxValuesForFetch = {
    sentenceBox: {
      sentenceId: null,
    },
    sentenceBoxAudio: {
      audioId: null,
      status: AUDIO_CRATE_STATUS.READY,
      apiState: CALL_STATE.NEW,
      url: null,
    },
    sentenceBoxOption: {
      lexo: null,
      language: string,
      voiceId: defaultVoice ? defaultVoice.id : 10, // 지영
    },
  }

  const addSentenceBoxesByCreator = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => ({ id, text }) => {
    const splidtedTexts = getSplitedCreatorTexts(text)
    // 첫 문장은 자기자신, 둘째 문장부터 추가
    const isEmptySplitedTexts = splidtedTexts.length <= 0
    if (isEmptySplitedTexts) {
      return
    }
    const firstSentenceText = splidtedTexts[0]

    const oldSentenceBoxText = snapshot.getLoadable(sentenceBoxValueState({ id, key: 'text' })).getValue()
    const oldSentenceBoxIndex = snapshot.getLoadable(sentenceBoxValueState({ id, key: 'index' })).getValue()
    const oldSentenceBoxOption = snapshot.getLoadable(sentenceBoxOptionState(id)).getValue()

    // 둘쨰 이후 문장: 첫 문장 제외하고 추가
    const addingSentences = removeItemAtIndex(splidtedTexts, 0).map((s) => ({
      id: uuidv4(),
      text: s,
    }))
    const oldSentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()
    // add sentenceBox ids
    const newBoxIds = addItemsAtIndex(
      oldSentenceBoxIds,
      oldSentenceBoxIndex + 1,
      addingSentences.map((s) => s.id),
    )

    transact_UNSTABLE(({ get, set }) => {
      set(sentenceBoxState(id), (prev) => ({
        ...prev,
        isSelected: true,
      }))
      updateFocused({ get, set })

      if (oldSentenceBoxIds !== newBoxIds) {
        set(sentenceBoxIdsState, newBoxIds)
      }

      // 첫 문장
      if (firstSentenceText === oldSentenceBoxText) {
        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          type: SENTENCEBOX_TYPE.VIEWER, // 첫 문장 처리
        }))
      } else {
        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBox,
          type: SENTENCEBOX_TYPE.VIEWER, // 첫 문장 처리
          text: firstSentenceText,
        }))

        set(sentenceBoxOptionState(id), (prev) => {
          // 새로 생성된 문장이면 디폴트를 넣는다 (sentenceBoxValues)
          // 기존에 있었던 문강이면 기존 문장의 옵션을 그대로 넣는다.
          const overrideValues = oldSentenceBoxText ? oldSentenceBoxOption : sentenceBoxValues
          return {
            ...prev,
            ...overrideValues,
          }
        })

        set(sentenceBoxAudioState(id), (prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
        }))
      }

      // Init Sentence Boxes
      for (const sentenceData of addingSentences) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { id, text } = sentenceData
        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          text,
        }))
        set(sentenceBoxOptionState(id), (prev) => {
          // 새로 생성된 문장이면 디폴트를 넣는다 (sentenceBoxValues)
          // 기존에 있었던 문강이면 기존 문장의 옵션을 그대로 넣는다.
          const overrideValues = oldSentenceBoxText ? oldSentenceBoxOption : sentenceBoxValues
          return {
            ...prev,
            ...overrideValues,
          }
        })
      }
    })
  })

  const addSentenceBoxesByFetch = useRecoilCallback(({ transact_UNSTABLE }) => ({ sentenceData }) => {
    if (!Array.isArray(sentenceData)) return
    if (sentenceData.length <= 0) return
    // sentenceBox Id, sentenceId 추가
    const addingSentences = sentenceData.map((data) => {
      const { attribute, content, lexo, id } = data
      const { audioId, status, url, duration, code } = attribute
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
      const { voiceId, space, pitch, speed, volume, language, locale } = attribute
      return {
        id: uuidv4(),
        sentenceBox: {
          sentenceId: id,
          text: content,
        },
        sentenceBoxAudio: {
          audioId,
          status,
          url,
          estimatedDurationMS: duration,
          errorCode: code,
        },
        sentenceBoxOption: {
          voiceId,
          space,
          pitch,
          speed,
          volume,
          language: locale,
          lexo,
        },
      }
    })

    const newBoxIds = addingSentences.map((s) => s.id)

    transact_UNSTABLE(({ get, set }) => {
      set(sentenceBoxIdsState, newBoxIds)
      // Init Sentence Boxes
      for (const addingSentence of addingSentences) {
        const { id } = addingSentence
        const { sentenceBox, sentenceBoxAudio, sentenceBoxOption } = addingSentence

        let duration = sentenceBoxAudio.estimatedDurationMS ? Number(sentenceBoxAudio.estimatedDurationMS) : 1000
        if (duration < 1000) duration = 1000

        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          sentenceId: sentenceBox.sentenceId,
          text: sentenceBox.text,
        }))

        console.log('seop audio', sentenceBoxAudio)

        set(sentenceBoxAudioState(id), (prev) => ({
          ...prev,
          audioId: sentenceBoxAudio.audioId ? sentenceBoxAudio.audioId : null,
          status: sentenceBoxAudio.status,
          url: sentenceBoxAudio.url ? sentenceBoxAudio.url : null,
          estimatedDurationMS: duration,
          errorCode: sentenceBoxAudio.errorCode,
        }))

        set(sentenceBoxOptionState(id), (prev) => ({
          ...prev,
          lexo: sentenceBoxOption.lexo ? sentenceBoxOption.lexo : null,
          voiceId: sentenceBoxOption.voiceId,
          space: sentenceBoxOption.space,
          pitch: sentenceBoxOption.pitch,
          speed: sentenceBoxOption.speed,
          volume: sentenceBoxOption.volume,
          language: sentenceBoxOption.language ? sentenceBoxOption.language : 'ko-KR',
        }))
      }

      const firstSentence = addingSentences[0]
      const firstSentenceText = firstSentence.sentenceBox.text

      const isValidSentenceBoxLength = checkIsValidSentenceBoxLength(firstSentenceText)
      if (isValidSentenceBoxLength) {
        set(sentenceBoxState(firstSentence.id), (prev) => ({
          ...prev,
          isSelected: true,
        }))
        updateFocused({ get, set })
      }
    })
  })

  const updateSentenceBoxesByFetch = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => ({ sentenceData }) => {
    if (!Array.isArray(sentenceData)) return
    if (sentenceData.length <= 0) return

    const viewerBoxIds = snapshot.getLoadable(viewerBoxIdsState).getValue()

    const updatingSentences = sentenceData.map((data, index) => {
      const { attribute, lexo, id } = data
      const { audioId, status, duration } = attribute
      return {
        id: viewerBoxIds[index],
        sentenceBox: {
          sentenceId: id,
        },
        sentenceBoxAudio: {
          audioId,
          status,
          estimatedDurationMS: duration,
        },
        sentenceBoxOption: {
          lexo,
        },
      }
    })

    transact_UNSTABLE(({ set }) => {
      // Update Sentence Boxes
      for (const updatingSentence of updatingSentences) {
        const { id } = updatingSentence
        const { sentenceBox, sentenceBoxAudio, sentenceBoxOption } = updatingSentence

        let duration = sentenceBoxAudio.estimatedDurationMS ? Number(sentenceBoxAudio.estimatedDurationMS) : 1000
        if (duration < 1000) duration = 1000

        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          sentenceId: sentenceBox.sentenceId,
        }))

        set(sentenceBoxAudioState(id), (prev) => ({
          ...prev,
          audioId: sentenceBoxAudio.audioId ? sentenceBoxAudio.audioId : null,
          status: sentenceBoxAudio.status,
          estimatedDurationMS: duration,
        }))

        set(sentenceBoxOptionState(id), (prev) => ({
          ...prev,
          lexo: sentenceBoxOption.lexo ? sentenceBoxOption.lexo : null,
        }))
      }
    })
  })

  const insertCreatorAtIndex = useRecoilCallback(({ snapshot, set, transact_UNSTABLE }) => (addingIndex) => {
    /** creator box 추가 */
    const sentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()
    const creatorId = uuidv4()
    const newSentenceBoxIds = addItemsAtIndex(sentenceBoxIds, addingIndex, [creatorId])
    transact_UNSTABLE(() => {
      // 추가
      set(sentenceBoxIdsState, newSentenceBoxIds)

      set(sentenceBoxState(creatorId), (prev) => ({
        ...prev,
        type: SENTENCEBOX_TYPE.CREATOR,
      }))
    })

    // remove old creator
    // const sentenceBoxes = newSentenceBoxIds.map((sentenceBoxId) =>
    //   get(sentenceBoxState(sentenceBoxId))
    // );
    // const oldCreatorIndex = sentenceBoxes.findIndex(
    //   (value) => value.type === SENTENCEBOX_TYPE.CREATOR
    // );
    // if (oldCreatorIndex !== -1) {
    //   newSentenceBoxIds = removeItemAtIndex(
    //     newSentenceBoxIds,
    //     oldCreatorIndex
    //   );
    // }

    // update ids
  })

  const removeSentenceBoxAtIndex = useRecoilCallback(({ snapshot, set }) => (index) => {
    const sentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()
    const newSentenceBoxIds = removeItemAtIndex(sentenceBoxIds, index)
    set(sentenceBoxIdsState, newSentenceBoxIds)
  })

  const deleteSelectedBoxes = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const isSelectedBoxesEmpty = snapshot.getLoadable(isSelectedBoxesEmptyState).getValue()
    if (isSelectedBoxesEmpty) return

    const sentenceBoxes = snapshot.getLoadable(sentenceBoxesByTypeState(SENTENCEBOX_TYPE.VIEWER)).getValue()

    const newValue = sentenceBoxes.filter((box) => !box.isSelected).map((box) => box.id)

    transact_UNSTABLE(({ set, reset }) => {
      set(sentenceBoxIdsState, newValue)
      reset(editorState)
    })
  })

  const deleteAllBoxes = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const isViewerBoxesEmpty = snapshot.getLoadable(isViewerBoxesEmptyState).getValue()
    if (isViewerBoxesEmpty) return

    transact_UNSTABLE(({ reset }) => {
      reset(sentenceBoxIdsState)
      reset(editorAudioState)
      reset(editorState)
    })
  })

  const resetStatesAtCreatorOpen = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => async () => {
    const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()

    if (selectedBoxIds.length === 0) return

    transact_UNSTABLE(({ get, set }) => {
      for (const id of selectedBoxIds) {
        // unselect all
        set(sentenceBoxState(id), (prev) => ({
          ...prev,
          isSelected: false,
        }))

        // reset audio
        resetSentenceBoxAudio({ id, set })
        resetFirstIntervalAudio({ set })
        resetIntervalAudio({ id, set })
      }

      // update focused
      updateFocused({ get, set })
    })
  })

  const resetSentenceBoxAtClickLanguage = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => ({ language }) => {
    const focusedBoxOption = snapshot.getLoadable(focusedBoxState({ category: SENTENCEBOX_CATEGORY.OPTION })).getValue()

    if (focusedBoxOption.language === language) return

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const defaultVoice = snapshot.getLoadable(defaultVoiceState({ language })).getValue()
    const { id } = focusedBoxOption
    // TODO 다중옵션 적용시 아래 주석 살려서 선택된것들 모두 초기화 유도해야됨
    // const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()
    transact_UNSTABLE(({ set }) => {
      set(sentenceBoxState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBox,
      }))
      set(sentenceBoxOptionState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBoxOption,
        language,
        voiceId: defaultVoice.id,
      }))
      set(sentenceBoxAudioState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
      }))
      // selectedBoxIds.forEach((id) => {
      //   set(sentenceBoxState(id), (prev) => ({
      //     ...prev,
      //     ...defaultSentenceBoxValuesForFetch.sentenceBox,
      //   }))
      //   set(sentenceBoxOptionState(id), (prev) => ({
      //     ...prev,
      //     ...defaultSentenceBoxValuesForFetch.sentenceBoxOption,
      //     language,
      //     voiceId: defaultVoice.id,
      //   }))
      //   set(sentenceBoxAudioState(id), (prev) => ({
      //     ...prev,
      //     ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
      //   }))
      // })

      setSentenceBoxValues({ ...sentenceBoxValues, voiceId: defaultVoice.id, language })
    })
  })

  const resetSentenceBoxOptions = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const focusedBoxOption = snapshot.getLoadable(focusedBoxState({ category: SENTENCEBOX_CATEGORY.OPTION })).getValue()

    const { id } = focusedBoxOption
    transact_UNSTABLE(({ set }) => {
      set(sentenceBoxState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBox,
      }))
      set(sentenceBoxOptionState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBoxOption,
        space: 0.5,
        speed: 1,
        pitch: 1,
        volume: 1,
      }))
      set(sentenceBoxAudioState(id), (prev) => ({
        ...prev,
        ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
      }))
    })
  })

  const applyAllOptions = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const focusedBoxOption = snapshot.getLoadable(focusedBoxState({ category: SENTENCEBOX_CATEGORY.OPTION })).getValue()
    if (!focusedBoxOption) return

    const { id } = focusedBoxOption

    const sentenceBoxIds = snapshot.getLoadable(sentenceBoxIdsState).getValue()

    const validSentenceBoxIds = sentenceBoxIds.filter((sentenceBoxId) => {
      const isValid = snapshot.getLoadable(isValidSentenceBoxState(sentenceBoxId))
      return isValid
    })
    transact_UNSTABLE(({ set }) => {
      for (const sentenceBoxId of validSentenceBoxIds) {
        // eslint-disable-next-line no-continue
        if (sentenceBoxId === id) continue

        set(sentenceBoxState(sentenceBoxId), (prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBox,
        }))
        set(sentenceBoxOptionState(sentenceBoxId), (prev) => ({
          ...prev,
          ...focusedBoxOption,
          lexo: prev.lexo,
          id: prev.id,
        }))
        set(sentenceBoxAudioState(sentenceBoxId), (prev) => ({
          ...prev,
          ...defaultSentenceBoxValuesForFetch.sentenceBoxAudio,
        }))
      }
    })
  })

  const setOptionValues = useRecoilCallback(({ snapshot, set }) => ({ optionKey, optionValue }) => {
    // 기준은 포커스 박스
    const focusedBoxOption = snapshot.getLoadable(focusedBoxState({ category: SENTENCEBOX_CATEGORY.OPTION })).getValue()
    set(
      sentenceBoxOptionValueState({
        id: focusedBoxOption.id,
        key: optionKey,
      }),
      optionValue,
    )

    // TODO 다중옵션시 이 주석 풀어야됨
    // // multi 면 나머지 것도 다 바꿈
    // const isMultiSelected = snapshot.getLoadable(isMultiSelectedState).getValue()

    // let nominatedBoxes = []
    // if (isMultiSelected) {
    //   nominatedBoxes = snapshot
    //     .getLoadable(
    //       selectedBoxesByCategoryState({
    //         category: SENTENCEBOX_CATEGORY.OPTION,
    //       }),
    //     )
    //     .getValue()

    //   nominatedBoxes = nominatedBoxes
    //     // focused box 제외
    //     .filter((box) => box.id !== focusedBoxOption.id)
    //   // Validation Check
    //   nominatedBoxes = nominatedBoxes.filter((box) => {
    //     const isValid = snapshot.getLoadable(isValidSentenceBoxState(box.id)).getValue()
    //     return isValid
    //   })
    // }

    // transact_UNSTABLE(({ set }) => {
    //   if (!isMultiSelected) return
    //   if (nominatedBoxes.length <= 0) return

    //   for (const box of nominatedBoxes) {
    //     set(sentenceBoxOptionState(box.id), (prev) => ({
    //       ...prev,
    //       [optionKey]: optionValue,
    //     }))
    //   }
    // })
  })

  const resetSelectAndFocusedAtClickEditor = useRecoilCallback(({ snapshot, transact_UNSTABLE }) => () => {
    const selectedBoxIds = snapshot.getLoadable(selectedBoxIdsState).getValue()
    if (selectedBoxIds.length <= 0) return

    transact_UNSTABLE(({ set, reset }) => {
      reset(editorState)
      selectMultipleBoxes({ set, boxIds: selectedBoxIds })
    })
  })

  return {
    addSentenceBoxesByCreator,
    addSentenceBoxesByFetch,
    updateSentenceBoxesByFetch,
    insertCreatorAtIndex,
    removeSentenceBoxAtIndex,
    deleteSelectedBoxes,
    deleteAllBoxes,
    resetStatesAtCreatorOpen,
    resetSentenceBoxAtClickLanguage,
    resetSentenceBoxOptions,
    applyAllOptions,
    setOptionValues,
    resetSelectAndFocusedAtClickEditor,
  }
}

export default useEditorCallbacks
