// import React, { useEffect } from 'react'
// import useDialog from '../../../../hooks/useDialog'
// import JeomGumDialog from './JeomGumDialog'
import { Alert, AlertTitle } from '@mui/material'
import { useState } from 'react'

// 긴급하게 뭐 띄울때 이걸 사용하도록..

export default function Jeomgum() {
  // 점검용 임시코드
  // const dialog = useDialog()
  // useEffect(() => {
  //   console.log('ssibal')
  //   dialog.open(<JeomGumDialog />)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const [closed, setClosed] = useState(false)

  return (
    <Alert
      severity="warning"
      className="overflow-hidden [&_.MuiAlert-message]:w-full"
      style={{ height: closed ? 0 : undefined, padding: closed ? 0 : undefined }}
    >
      <div className="flex w-full justify-between">
        <AlertTitle>서비스 점검 공지</AlertTitle>
        <button type="button" className="text-lg" onClick={() => setClosed(true)}>
          X
        </button>
      </div>
      AiVATAR 서비스의 안정적인 서비스 제공을 위해 서비스 이용이 일시적으로 중단되오니 양해 부탁드립니다. <br />
      점검 시간: 12월 17일(화) 05:00 ~ 07:00
    </Alert>
  )
}
